import FPDManager from './fpd-manager.js'
import $ from 'jquery'

import { includes } from 'lodash'

export default class ExternalElementsOperator {
  constructor(productPage) {
    this.productPage = productPage
    this.fpdManager = productPage.fpdManager
  }

  get taEE() {
    return {
      moduleWrapper: '.fpd-sc-module-wrapper',
      actionPlaceholder: '.fpd-sc-action-placeholder'
    }
  }

  /**
   * Waiting vor fpd:ui-set and fpd:ready and initializing the external
   * elements.
   *
   */
  initListener() {
    this.fpdManager.addDesignerReadyCallback(this.initExternalActions.bind(this))
    this.fpdManager.addUiSetCallback(this.initExternalModules.bind(this))
    this.fpdManager.addUiSetCallback(this.initDynamicDesignModules.bind(this))
    this.fpdManager.addDesignerReadyCallback(this.initManageLayers.bind(this))
    this.fpdManager.addDesignerReadyCallback(this.initTextLayersList.bind(this))
    this.fpdManager.addDesignerReadyCallback(this.initNamesNumbers.bind(this))
  }

  initExternalModules() {
    const that = this
    $(that.taEE.moduleWrapper).each((_index, moduleWrapper) => {
      const $moduleWrapper = $(moduleWrapper)
      const moduleType = $moduleWrapper.data('type')
      const moduleUiSelector = `.fpd-modules > [data-module=${moduleType}]`
      const $moduleClone = that.fpdManager.translatedUI.find(moduleUiSelector).clone()
      const additionalElementOptions = $moduleWrapper.data('fpd-aeo') || {}

      $moduleClone.appendTo($moduleWrapper)
      that.fpdManager.createModule(moduleType, $moduleClone, additionalElementOptions)
    })
  }

  initDynamicDesignModules() {
    const that = this
    $(`${that.taEE.moduleWrapper}[data-type^='designs_']`).each((_index, moduleWrapper) => {
      const $moduleWrapper = $(moduleWrapper)

      const dynamicDesignId = $moduleWrapper.data('type').split('_').pop()
      const dynamicDesignsModules = that.fpdManager.wrappedInstance.getDynamicDesignModules()

      if(dynamicDesignId && dynamicDesignsModules[dynamicDesignId]) {
        console.debug(`Loaidng Dynamic Design Module ${dynamicDesignId}.`)

        const moduleUiSelector = ".fpd-modules > [data-module='designs']"
        const $moduleClone = that.fpdManager.translatedUI.find(moduleUiSelector).clone()
        $moduleClone.appendTo($moduleWrapper)

        that.fpdManager.createModule('designs', $moduleClone, dynamicDesignId)

      } else {
        console.debug(`Dynamic Design Module ${dynamicDesignId} not defined.`)
      }

    })
  }

  initTextLayersList() {
    const that = this
    $(document).on('fpd:element:added fpd:element:removed fpd:view:selected', (event) => {
      that.updateTextLayers()
    })
  }

  updateTextLayers() {
    const that = this
    $(`${that.taEE.moduleWrapper}[data-type="text-layers"]`).each((_index, moduleWrapper) => {

      let $moduleWrapper = $(moduleWrapper);
      const fpdTextLayersModuleClass = that.fpdManager.getModuleClass('FPDTextLayersModule')
      fpdTextLayersModuleClass.createList(
        that.fpdManager.instance,
        $moduleWrapper.children('.fpd-module')
      )
    })
  }

  initManageLayers() {
    const that = this
    $(document).on('fpd:element:added fpd:element:removed fpd:view:selected', (event) => {
      that.updateManagerLayers()
    })
  }

  updateManagerLayers() {
    const that = this
    $(`${that.taEE.moduleWrapper}[data-type="manage-layers"]`).each((_index, moduleWrapper) => {

      let $moduleWrapper = $(moduleWrapper);
      const fpdLayersModuleClass = that.fpdManager.getModuleClass('FPDLayersModule')
      fpdLayersModuleClass.createList(
        that.fpdManager.instance,
        $moduleWrapper.children('.fpd-module')
      )
    })
  }

  initNamesNumbers() {
    const that = this
    $(document).on('fpd:view:selected', (event) => {
      that.updateNamesNumbers()
    })
  }

  updateNamesNumbers() {
    const that = this
    $(`${that.taEE.moduleWrapper}[data-type="names-numbers"]`).each((_index, moduleWrapper) => {
      let $moduleWrapper = $(moduleWrapper);
      const fpdNamesNumbersModuleClass = that.fpdManager.getModuleClass('FPDNamesNumbersModule')
      fpdNamesNumbersModuleClass.setup(
        that.fpdManager.instance,
        $moduleWrapper.children('.fpd-module')
      )
    });
  }


  /**
   * Replaces html snippetes with action icons or links.
   *
   * For example like:
   * `<div class="fpd-sc-action-placeholder" data-action="print" layout="icon-text | text"></div>`
   *
   * Attention - no unit tests present.
   */
  initExternalActions() {
    const that = this
    that.$uiActions = that.fpdManager.translatedUI.children('.fpd-actions')
    $(that.taEE.actionPlaceholder).each((_index, $item) => {
      $item = $($item)

      const actionName = $item.data('action')
      const layout = $item.data('layout')
      const $action = this.$uiActions.children(`[data-action=${actionName}]`)

      const $cloneAction = $action.clone()
      $cloneAction.addClass(`fpd-sc-action fpd-layout--${layout}`)
      $cloneAction.removeClass('fpd-disabled')

      if(layout === 'icon-text' || layout === 'text') {
        $cloneAction.removeClass('fpd-tooltip')
        $cloneAction.children(':first').after(`<span class="fpd-label">${$cloneAction.attr('title')}</span>`);
      }

      $cloneAction.click(() => {
        that.fpdManager.doAction($cloneAction)
      })

      $item.replaceWith($cloneAction)
    })
  }
}
