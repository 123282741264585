import { FPDUtil } from '../lib/FancyProductDesigner.js'
import WebFont from '../lib/webfont.js'

import { isObject, each } from 'lodash'
import $ from 'jquery'

const FONT_STYLE_ID = 'fpd-custom-fonts'

export default class FontLoader {

  static load(fonts) {
    new FontLoader(fonts).load()
  }

  constructor(fonts) {
    this.fonts = fonts
    this.googleFonts = []
    this.customFonts = []
    this.fontStateCount = 0
    this.$customFontsStyle = null
    this.fontStateCount = 0
    this.WebFont = WebFont
  }

  load() {
    if(!this.areFontsValid()) {
      return Promise.reject()
    }
    return new Promise((resolve, reject) => {
      this.resolveProcessingPromise = resolve
      this.rejectProcessingPromise = reject

      this.initOrRestFontStyles()

      each(this.fonts, (fontItem) => {
        this.processFontItem(fontItem)
      })

      this.executeLoading()

    }).catch(error => {
      console.error(error)
    })
  }

  initOrRestFontStyles() {
    if($(`#${FONT_STYLE_ID}`).length == 0) {

      this.$customFontsStyle = $(`<style type="text/css" id="${FONT_STYLE_ID}"></style>`);
      $('body').append(this.$customFontsStyle);

    } else {
      this.$customFontsStyle = $(`#${FONT_STYLE_ID}`).empty();
    }
  }

  processFontItem(fontItem) {
    if(fontItem.hasOwnProperty('url')) {
      if(fontItem.url == 'google') {
        // load google fonts
        this.googleFonts.push(fontItem.name+':400,400i,700,700i');
      } else {
        // load custom fonts
        let fontFamily = fontItem.name;

        fontFamily += ':n4'

        if(fontItem.variants) {
          fontFamily += ',' + Object.keys(fontItem.variants).toString();
        }

        this.customFonts.push(fontFamily);
        this.$customFontsStyle.append(FPDUtil.parseFontsToEmbed(fontItem));
      }
    }
  }

  executeLoading() {
    const that = this
    const WebFontOpts = {
      fontactive: (familyName, fvd) => {
        that.fontActiveState('active', familyName, fvd)
      },
      fontinactive: (familyName, fvd) => {
        that.fontActiveState('inactive', familyName, fvd)
      },
      timeout: 3000
    }

    if(this.googleFonts.length > 0) {
      WebFontOpts.google = {families: this.googleFonts};
    }

    if(this.customFonts.length > 0) {
      WebFontOpts.custom = {families: this.customFonts};
    }

    if(typeof WebFont !== 'undefined' && (this.googleFonts.length > 0 || this.customFonts.length > 0)) {
      this.WebFont.load(WebFontOpts);
    }
    else {
      this.resolveProcessingPromise()
    }
  }

  /**
   * function for loading process of WebFonts.
   * Counts successful loaded fonts and resolve main promise when done.
   *
   * @param  {[type]} state      active or inactive
   * @param  {[type]} familyName font name
   * @param  {[type]} fvd        unused - passthrough
   */
  fontActiveState(state, familyName, fvd) {
    if(state == 'inactive') {
      FPDUtil.log(`${familyName} font could not be loaded.`, 'warn');
    }

    if(this.fontStateCount == (this.googleFonts.length + this.customFonts.length)-1) {
      this.resolveProcessingPromise()
    }

    this.fontStateCount++;
  }

  areFontsValid() {
    return this.fonts && this.fonts.length > 0 && isObject(this.fonts[0])
  }
}
