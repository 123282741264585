import ProductPage from '../pages/product-page.js'
import FPDManager from '../services/fpd-manager.js'
import LanguageModel from '../models/language-model.js'

/**
 * Setup the designer on the product page.
 *
 * It does:
 * - reads the shopify product from json
 * - triggers loads the fancy product
 * - creates the lic manager
 * - triggers set up of event listen for AtC
 */
export default function initProductPage() {
  const productPage = new ProductPage(FPD.optionsManager, FPDManager)
  productPage.initThemeAbstraction()

  Promise.all([
    productPage.loadFancyProduct(),
    productPage.loadExistingLic()
  ])
  .then(() => {
    LanguageModel.init(productPage.productModel.getLanguageJson().shopify)

    productPage.buildLicManager(FPDManager)
    productPage.initPageListeners()
    productPage.prepareDesignerMode()

    FPDManager.addDesignerReadyCallback(() => {
      if(productPage.existingLic) {
        FPDManager.instance.loadProduct(productPage.existingLic.data.product)
        productPage.checkToOpenDesigner()
      }
      // this only happens in cb mode
      productPage.cbOperator.initDoneListener()
    })
    FPDManager.addProductCreateCallback(() => {
      productPage.curvedTextAdjustment()
    })

    FPDManager.addProductCreateCallback(() => {
      productPage.curvedTextAdjustment()
    })

    FPDManager.createDesigner(
      productPage.$fpdNode,
      productPage.fpdProductJSON.plugin_options
    )
    FPDManager.setupPlus()

    FPD.instance = FPDManager.instance
  })
  .catch(error => {
    console.debug("Error:", error)
    console.debug("Stopping initialization of the product page.")
  })



  FPD.productPage = productPage


  FPD.func.fpdDebug = productPage.debugTa.bind(productPage);

  FPD.func.FPDDynamicViews = FPDManager.getModuleClass('FPDDynamicViews');
  FPD.func.FPDUtil = FPDManager.getModuleClass('FPDUtil');
  FPD.func.FancyProductDesigner = FPDManager.getModuleClass('FancyProductDesigner');
  FPD.func.FancyProductDesignerView = FPDManager.getModuleClass('FancyProductDesignerView');
  FPD.LanguageModel = LanguageModel
}

