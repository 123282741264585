import { merge, isNil } from 'lodash'

export default class LanguageModel {
  static _instance = null
  static get instance() {
    return LanguageModel._instance
  }

  static init(languageJson) {
    LanguageModel._instance = new LanguageModel()
    LanguageModel.instance.language = merge({}, languageJson, window.FPD.lang)
  }

  static lookup(key, defaultValue) {
    if(isNil(LanguageModel.instance)) {
      throw new Error('LanguageModel not initalized.')
    }
    return LanguageModel.instance.lookup(key, defaultValue)
  }

  constructor() {
    this._language = {}
  }

  get language() {
    return this._language
  }

  set language(language) {
    this._language = language
  }

  lookup(key, defaultValue) {
    return this.language[key] || defaultValue
  }
}
