import ProductPage from './pages/product-page.js'
import FPDManager from './services/fpd-manager.js'
import LicManager from './services/lic-manager.js'
import initStorefront from './processes/init-storefront.js'
import jQuery from 'jquery'

import {
  FancyProductDesigner,
  FancyProductDesignerView,
  FPDProductsModule,
  FPDTextModule,
  FPDDesignsModule,
  FPDImagesModule,
  FPDLayoutsModule,
  FPDTextLayersModule,
  FPDLayersModule,
  FPDUtil
} from './lib/FancyProductDesigner.js'

import {
  FPDNamesNumbersModule,
  FPDDrawingModule,
  FancyProductDesignerPlus,
  FPDDynamicViews
} from './lib/FancyProductDesignerPlus.js'


FPDManager.addMappedClass('FPDProductsModule', FPDProductsModule)

FPDManager.addMappedClass('FPDProductsModule', FPDProductsModule)
FPDManager.addMappedClass('FPDTextModule', FPDTextModule)
FPDManager.addMappedClass('FPDDesignsModule', FPDDesignsModule)
FPDManager.addMappedClass('FPDImagesModule', FPDImagesModule)
FPDManager.addMappedClass('FPDLayoutsModule', FPDLayoutsModule)
FPDManager.addMappedClass('FPDDrawingModule', FPDDrawingModule)
FPDManager.addMappedClass('FPDTextLayersModule', FPDTextLayersModule)
FPDManager.addMappedClass('FPDLayersModule', FPDLayersModule)
FPDManager.addMappedClass('FPDNamesNumbersModule', FPDNamesNumbersModule)
FPDManager.addMappedClass('FPDDynamicViews', FPDDynamicViews)

FPDManager.addMappedClass('FPDUtil', FPDUtil)
FPDManager.addMappedClass('FancyProductDesigner', FancyProductDesigner)
FPDManager.addMappedClass('FancyProductDesignerView', FancyProductDesignerView)
FPDManager.addMappedClass('FancyProductDesignerPlus', FancyProductDesignerPlus)

if(typeof FPD !== 'undefined') {
 initStorefront()
}

export { jQuery }







