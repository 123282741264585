import OptionsManager from '../services/options-manager.js'
import CartPage from '../pages/cart-page.js'
import ProductPage from '../pages/product-page.js'
import GeneralPage from '../pages/general-page.js'
import initProductPage from './init-product-page.js'
import initCartPage from './init-cart-page.js'
import initGeneralPage from './init-general-page.js'

import { formatMoney } from '../helpers/money-formatter.js'

import '../assets/css/static.css'
import '../assets/css/FancyProductDesigner.css'
import '../assets/css/fpd-fe-shopify.css'
import '../assets/css/plugins.min.css'

/**
 *  InitStorefront execues on each page load
 *  and is responsible for:
 *  - init FPD object with defaults and store specifics
 *  - load the shop options
 *  - load the designs
 *  - init the theme abstraction
 *  - init product page if needed
 *  - init cart page if needed
 *  - init all pages ...
 */
export default function initStorefront() {

  const onProductPage = ProductPage.isUrl()
  const onCartPage = CartPage.isUrl()
  const onGeneralPage = GeneralPage.isUrl()

  const optionsConfig = {
    shopifyDomain: Shopify.shop,
    fpdAdminUrl: process.env.ADMIN_SERVER_HOST,
    apiUrl: process.env.RAILS_SERVER_HOST
  }
  let optionsManager = new OptionsManager(optionsConfig)
  optionsManager.initFPDObject()
  // Refactor sets FPD.shopOptions, FPD.subscription,
  // FPD.suggestedSelectors and add FPD.loadingPromises to loading.
  optionsManager.loadShopOptions()
  // Refactor sets allDesignsJSON and adds promise to FPD.loadingPromises.
  optionsManager.loadDesigns()

  optionsManager.loadingPromise.then(() => {
    optionsManager.initThemeAbstraction()
    if(onGeneralPage) {
      initGeneralPage(!onProductPage && !onCartPage)
    }
    if(onProductPage) {
      initProductPage()
    }
    if(onCartPage) {
      initCartPage()
    }
  })

  FPD.optionsManager = optionsManager
  FPD.fpdAdminUrl = optionsManager.fpdAdminUrl
  FPD.func = {};

  FPD.formatMoney = formatMoney
}
