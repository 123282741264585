import { get, isNil, isNumber, isEmpty } from 'lodash'
import $ from 'jquery'

import LanguageModel from '../models/language-model.js'
import { formatMoney } from '../helpers/money-formatter.js'

export default class CustomPriceOperator {

  static buildForProductPage(productPage) {
    const cpo       = new CustomPriceOperator()
    cpo.basePrice   = productPage.getCurrentShopifyVariant().price
    cpo.$atcForm    = productPage.$addToCartForm
    cpo.ta          = productPage.taProduct
    cpo.moneyFormat = productPage.optionsManager.moneyFormat

    cpo.initElements()

    return cpo
  }

  constructor() {
    this._basePrice = 0
    this._ta = {}
    this._moneyFormat = '${{amount}}'
    this._$atcForm = null
    this.$priceElement = null
    this.$totalExtra = null
  }

  get ta() {
    return this._ta
  }
  set ta(ta) {
    this._ta = ta
  }
  get basePrice() {
    return this._basePrice
  }
  set basePrice(cents) {
    this._basePrice = cents
  }
  get moneyFormat() {
    return this._moneyFormat
  }
  set moneyFormat(format) {
    this._moneyFormat = format
  }
  get $atcForm() {
    return this._$atcForm
  }
  set $atcForm($element) {
    if(isNil($element.jquery)) {
      $element = $($element)
    }
    this._$atcForm = $element
  }

  initElements() {
    if($(this.ta.price).length == 0) {
      this.createTotalExtra()
    } else {
      this.$priceElement = $(this.ta.price)
    }
  }

  updateWithExtra(extraAmount) {
    const extraCents = parseInt(extraAmount * 100)

    this.initElements()

    if(this.$priceElement && this.$priceElement.length > 0) {
      this.updatePrice(this.basePrice + extraCents)
    } else {
      this.updateExtraPrice(extraCents)
    }
  }

  currentPriceIsNull() {
    const $currentPrice = $(this.ta.price)
    if($currentPrice.length == 0) {
      return true
    }
    const parsedPrice = parseFloat($currentPrice.html())

    if(parsedPrice == NaN)
      return true
    if(parsedPrice == 0)
      return true

    return false
  }

  updatePrice(newPriceCents, format) {
    if(isEmpty(format)) {
      format = this._moneyFormat
    }
    if(this.$priceElement.length == 0) {
      throw new Error('No price element.')
    }
    this.$priceElement.html(formatMoney(newPriceCents, format))
  }

  updateExtraPrice(newPriceCents, format) {
    if(isEmpty(format)) {
      format = this._moneyFormat
    }
    if(this.$totalExtra.length == 0) {
      throw new Error('No total extra element.')
    }

    if(parseFloat(newPriceCents) == 0) {
      this.$totalExtra.hide()
    } else {
      this.$totalExtra.show()
    }

    const $totalExtraPrice = this.$totalExtra.find(this.ta.price)
    $totalExtraPrice.html(formatMoney(newPriceCents, format))
  }

  createTotalExtra() {
    this.$totalExtra = $(`<div class='fpd-total-extra'>${LanguageModel.lookup('totalExtra')} <span class='fpd-shopify-price'></span></div>`)
    this._ta.totalExtra = '.fpd-total-extra'
    this._ta.price = '.fpd-shopify-price'

    this.$atcForm.prepend(this.$totalExtra)
    this.$totalExtra.hide()
  }
}
