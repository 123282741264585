import ProductPageFormOperator from './product-page-form-operator.js'
import LanguageModel from '../models/language-model.js'

import $ from 'jquery'
import { get, each, isNil } from 'lodash'

export default class SaveButtonOperator extends ProductPageFormOperator {
  constructor(productPage) {
    super(productPage)
    this.$saveButton = null
    this.$fpdLoadingSpan = $('<span class=fpd-waiting>&nbsp;</span>')
    this.saveButtonStrategy = null
  }

  get licManager() {
    return this.productPage.licManager
  }

  get taProduct() {
    return this.productPage.taProduct
  }

  get existingLic() {
    return this.productPage.existingLic
  }

  initListener() {
    if(! this.optionsManager.isUseSaveButton() &&
      this.optionsManager.getSaveButtonStrategy() == 'save_to_form') {
      return
    }

    this.setupSaveButton()

    if(this.optionsManager.isSaveButtonReplaceAtc()) {
      this.replaceAtcButton()
    }

    this.setSaveButtonStrategry()
  }

  clickHandler(evt) {
    evt.preventDefault()

    this.setOriginalAtcButtonDisabled(true)
    this.setSaveButtonLoading(true)

    this.saveButtonStrategy()

  }

  setupSaveButton() {
    this.$saveButton = $(this.taProduct.saveCustomization)
    if(this.$saveButton.length == 0) {
      this.$saveButton = this.createSaveButton()
    }
    this.$saveButton.on('click.fpd', this.clickHandler.bind(this))
  }

  replaceAtcButton() {
    this.$atcButton = $(this.getAtcButtons()[0])
    this.$saveButton.addClass(this.$atcButton.attr('class'))
    this.$atcButton.replaceWith(this.$saveButton)
  }

  setSaveButtonStrategry() {
    const saveButtonStrategy = this.optionsManager.getSaveButtonStrategy()

    switch(saveButtonStrategy) {
      case 'save_to_form':
        console.debug('sb strategy - save_to_form')
        this.saveButtonStrategy = this.saveProductToForm
        break
      case 'update_line_item_cache':
        this.saveButtonStrategy = this.updateLineItemCache
        console.debug('sb strategy - save_to_form')
        break
      default:
        throw `${saveButtonStrategy} - Unknown save button strategy.`
    }
  }

  saveProductToForm() {
    const shopifyProduct = this.getShopifyProduct()
    const variant = this.getCurrentShopifyVariant()

    this.productPage.licManager.processLicCreation(shopifyProduct, variant)
    .then((lineItemCache) => {
      this.addProductToForm(lineItemCache, shopifyProduct)

      this.setOriginalAtcButtonDisabled(false)
      this.setSaveButtonLoading(false)

      const currentButtonText = this.$saveButton.html()
      this.$saveButton.html(LanguageModel.lookup('saveUpdated', currentButtonText))
    })
  }

  updateLineItemCache() {
    this.licManager.processLicUpdate(this.existingLic)
      .then(_result => {

        this.setOriginalAtcButtonDisabled(false)
        this.setSaveButtonLoading(false)

        const currentButtonText = this.$saveButton.html()
        this.$saveButton.html(LanguageModel.lookup('saveUpdated', currentButtonText))
      })
  }

  addProductToForm(lineItemCache, shopifyProduct) {
    let properties = this.buildProperties(lineItemCache, shopifyProduct)

    let formData = new FormData(this.productPage.$addToCartForm[0]) // access non jq node

    /**
     * TODO:
     * - should be renamed
     */
    if(this.optionsManager.hasCallback('afterSaveCustomization')) {
      properties = this.optionsManager.invokeCallback('afterSaveCustomization', properties)
    }

    $.each(properties, (key, value) => {
      if(!key.includes('properties')) {
        key = `properties[${key}]`
      }
      this.replaceOrCreateInput(key, value)
    })

    this.replaceOrCreateInput('id', this.getVariantId(lineItemCache))
  }

  setSaveButtonLoading(loading) {
    if(loading) {
      this.$saveButton.prepend(this.$fpdLoadingSpan)
    } else {
      this.$fpdLoadingSpan.detach()
    }
    this.$saveButton.prop('disabled', loading)
  }

  createSaveButton($addToCartButton = null, saveButtonId = "fpd-save-button", hideAtc = false) {
    if($addToCartButton == null) {
      $addToCartButton = $(`${this.taProduct.addToCartForm} ${this.taProduct.addToCartFormSubmit}`)
      if($addToCartButton.length > 1) {
        $addToCartButton = $($addToCartButton[0])
      }
    }

    // setting selector
    const saveButtonSelector = `#${saveButtonId}`
    this.productPage.updateTa('product.saveCustomization', saveButtonSelector)

    const buttonText = LanguageModel.lookup('saveCustomizationButton', 'Save')
    const $saveButton = $(`<span id='${saveButtonId}'>${buttonText}</span>`)

    // add save button
    $addToCartButton.after($saveButton)

    return $saveButton
  }

  setOriginalAtcButtonDisabled(disabled) {
    const $atcButtons = $(`${this.taProduct.addToCartForm} ${this.taProduct.addToCartFormSubmit}`)
    each($atcButtons, ($button) => {
      $button = $($button)
      $button.prop('disabled', disabled)
    })
  }
}
